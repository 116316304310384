import { ClientRegistration } from "@a_team/models/dist/ClientRegistration";
import { CompanyId } from "@a_team/models/dist/Company";
import { RegisteredUserObject } from "@a_team/models/dist/UserObject";
import { getCookieByName } from "helpers/cookies";
import {
  AuthCredentials,
  AuthObject,
  GoogleRegisterCredentials,
  GoogleSignInBody,
  IdentityTokenBody,
  LoginResponse,
  LoginWithCredentials,
  RefreshTokenResponse,
  RegisterCompany,
  RegisterCredentials,
  RegisterEnrichmentResponse,
} from "models/Auth";
import serviceFetch from "services/helpers";
import { AuthStore } from "store/Auth";

export interface CompleteRegistrationResponse extends AuthObject {
  transcriptId?: string;
  intervieweeId?: string;
  missionSpecId?: string;
  sessionId?: string;
  redirectUrl?: string;
}

const getHubspotUtkHeader = () => {
  const cookieValue = getCookieByName("hubspotutk");
  return cookieValue ? { "x-hubspot-utk": cookieValue } : {};
};

export const registerUser = async (
  credentials: RegisterCredentials
): Promise<AuthObject> =>
  serviceFetch(null, "register", null, "post", credentials, {
    ...getHubspotUtkHeader(),
  });

export const registerUserWithGoogle = async (
  credentials: GoogleRegisterCredentials
): Promise<AuthObject> =>
  serviceFetch(null, "google-register", null, "post", credentials, {
    ...getHubspotUtkHeader(),
  });

export const getRegisterEnrichment = async (
  auth: AuthStore
): Promise<RegisterEnrichmentResponse> =>
  serviceFetch(auth, "register/enrichment", null, "get");

export const resendVerification = async (payload: {
  email: string;
}): Promise<null> =>
  serviceFetch(null, "register/resend-verification", null, "post", payload);

export const verifyRegistration = async (payload: {
  token: string;
}): Promise<AuthObject> =>
  serviceFetch(null, "register/verification", null, "post", payload);

export const updateAttributes = async (
  auth: AuthStore,
  payload: ClientRegistration
): Promise<RegisteredUserObject> =>
  serviceFetch(auth, "register/attributes", null, "put", payload);

export const registerComplete = async (
  auth: AuthStore,
  ipSessionId?: string
): Promise<CompleteRegistrationResponse> =>
  serviceFetch(
    auth,
    "register/complete",
    null,
    "post",
    { ipSessionId },
    {
      ...getHubspotUtkHeader(),
    }
  );

export const updateAttributesAndReview = async (
  auth: AuthStore,
  payload: {
    attributes: ClientRegistration;
    companyId: CompanyId;
  }
): Promise<CompleteRegistrationResponse | null> =>
  serviceFetch(auth, "register/attributes-and-review", null, "put", payload, {
    ...getHubspotUtkHeader(),
  });

export const registerCompany = async (
  auth: AuthStore,
  payload: RegisterCompany
): Promise<RegisterEnrichmentResponse> =>
  serviceFetch(auth, "register/company", null, "post", payload);

export const authenticateUser = async (
  credentials: AuthCredentials
): Promise<AuthObject> =>
  serviceFetch(null, "login", null, "post", credentials);

/** @deprecated - use loginWithIdentityTokenV2 */
export const loginWithIdentityToken = async (
  data: IdentityTokenBody
): Promise<AuthObject> =>
  serviceFetch(null, "identity-token-login", null, "post", data);

export const loginWithIdentityTokenV2 = async (
  data: IdentityTokenBody
): Promise<AuthObject> =>
  serviceFetch(null, "login-with-identity-token", null, "post", data);

export const loginWithGoogle = async (
  data: GoogleSignInBody
): Promise<AuthObject> =>
  serviceFetch(null, "google-login", null, "post", data);

export const getCurrentUser = async (
  auth: AuthStore
): Promise<RegisteredUserObject> =>
  serviceFetch(auth, "proxy/user", null, "get");

export const loginWith = async (
  data: LoginWithCredentials
): Promise<LoginResponse> =>
  serviceFetch(null, "proxy/auth/login-with", null, "post", data);

export const requestPasswordReset = async (email: string): Promise<unknown> =>
  serviceFetch(null, "reset-password", null, "post", { email });

export const resetPassword = async (
  uid: string,
  password: string,
  hashCode: string
): Promise<AuthObject> =>
  serviceFetch(null, `reset-password/${uid}`, null, "post", {
    password,
    hashCode,
  });

export const logout = async (auth: AuthStore): Promise<RegisteredUserObject> =>
  serviceFetch(auth, "logout", null, "post");

export const refreshToken = async (
  auth: AuthStore,
  refreshToken: string
): Promise<RefreshTokenResponse> =>
  serviceFetch(auth, "refresh", null, "post", { refreshToken });
